module.exports = {
  APP_BAR: 'app-bar',
  NAV_ANCHOR: 'nav-anchor',
  LANDING_HERO_HEADER: 'landing-hero-header',
  SHORTCUTS_BAR: 'shortcuts-bar',
  PGE_FOOTER: 'pge-footer',
  EMAIL_FIELD: 'emailField',
  PASSWORD_FIELD: 'passwordField',
  LOADING_SPINNER: 'loading-spinner',
  REPORT_OUTAGE_OPTIONAL_ID: 'report-outage-id', // submit button or inelgible message
  VIEW_BILL_H1: 'view-bill-h1',
  ACCOUNT_CARD: 'account-card',
  OUTAGE_LOCATION_LOOKUP: 'outage-location-lookup',
};
