import React, { FunctionComponent, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { navigate } from 'gatsby';
import useNavigation from '../../hooks/useNavigation';

import MobileNavigationMenu from './MobileNavMenu';
import PGELogo from '../logos/PGELogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolBar: {
      minHeight: 88,
      background: `transparent linear-gradient(188deg, #0064A2 0%, #0096A2 100%) 0% 0% no-repeat padding-box`,
      opacity: 0.9,
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontWeight: 700,
      fontSize: theme.typography.h6.fontSize,
    },
    logo: {
      height: 60,
      width: 132,
    },
    alignItem: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface MobileHeaderProps {
  title: string;
}

const MobileHeader: FunctionComponent<MobileHeaderProps> = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [navigation] = useNavigation();

  const toggleMenu = () => {
    setMenuOpen(false);
  };

  const navigateToRoute = (route: string) => (e: any) => {
    e.preventDefault();
    setMenuOpen(false);
    return navigate(route);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            id="hamburger-menu-icon"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setMenuOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <IconButton
            edge="end"
            aria-label="home"
            onClick={() => navigate('/')}
          >
            <PGELogo
              viewBox="0 0 752 252"
              color="#fff"
              className={classes.logo}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MobileNavigationMenu
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        navigateToRoute={navigateToRoute}
        navigation={navigation}
      />
    </div>
  );
};

export default MobileHeader;
