import React from 'react';
import { navigate } from 'gatsby';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import SearchIcon from '@material-ui/icons/Search';

import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'inline-block',
      position: 'relative',
    },
    closeButtonIcon: {
      color: colors.white,
    },
    elasticSearchBox: {
      boxSizing: 'border-box',
      margin: theme.spacing(0, 0, 1.25, 1.25),
      display: 'flex',
      '& .sui-search-box': {
        padding: theme.spacing(0, 1.25, 0, 0),
      },
      '& .sui-search-box__submit': {
        display: 'none',
      },
      '& .sui-search-box__text-input': {
        padding: theme.spacing(1),
      },
      '& > form': {
        width: '100%',
      },
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(1.375, 0, 0, -5),
        zIndex: 1,
        fill: colors.noirBlur,
        height: theme.spacing(2),
      },
      '& .MuiButton-outlinedPrimary': {
        marginLeft: theme.spacing(2),
      },
      '& .sui-search-box__autocomplete-container': {
        top: '97%',
      },
    },
  }),
);

type MobileNavigationControlsProps = {
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
};

const MobileNavigationControls = ({
  toggleMenu,
  navigateToRoute,
}: MobileNavigationControlsProps) => {
  const classes = useStyles();
  const isBrowser = !(typeof window === 'undefined');

  return (
    <>
      <div className={classes.controls}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <IconButton onClick={() => toggleMenu()}>
            <Close className={classes.closeButtonIcon} />
          </IconButton>
        </Box>
      </div>
      <div className={classes.elasticSearchBox}>
        {isBrowser && (
          <WithSearch
            mapContextToProps={({ searchTerm, setSearchTerm }: any) => ({
              searchTerm,
              setSearchTerm,
            })}
          >
            {({ searchTerm, setSearchTerm }: any) => {
              return (
                <>
                  <SearchBox
                    autocompleteMinimumCharacters={3}
                    autocompleteResults={{
                      sectionTitle: 'Results',
                      titleField: 'title',
                      urlField: 'url',
                      shouldTrackClickThrough: true,
                      clickThroughTags: ['test'],
                    }}
                    autocompleteSuggestions={true}
                    debounceLength={0}
                    onSubmit={async (value: any) => {
                      toggleMenu();
                      await navigate('/search?q=' + value);
                      setSearchTerm(value);
                    }}
                  />
                  <SearchIcon
                    style={{ cursor: 'pointer' }}
                    onClick={async (e: any) => {
                      toggleMenu();
                      await navigate('/search?q=' + searchTerm);
                      setSearchTerm(searchTerm);
                    }}
                  />
                </>
              );
            }}
          </WithSearch>
        )}
      </div>
    </>
  );
};

export default MobileNavigationControls;
