import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import React, { FunctionComponent, useState } from 'react';

import Trees from '../../../static/trees.svg';

import { graphql, useStaticQuery, Link } from 'gatsby';

import TEST_IDS from '../../../constants/test_ids';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      flex: 1,
    },
    backgrounds: {
      backgroundColor: '#0371b4',
    },
    policyLinks: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    treeContainer: {
      display: 'flex',
      height: '199px',
    },
    sectionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      height: '100%',
    },
    footerSection: {
      width: '90%',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },
    },
    mobileWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: theme.spacing(0, 0, 2, 0),
    },
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      margin: theme.spacing(0, 0, 0, 0),
      minWidth: 120,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        margin: 0,
      },
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1, 0, 1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 2, 0, 2),
      },
    },
    corporateContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 1),
    },
    divider: {
      flex: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#FFFFFF',
      margin: theme.spacing(0, 0, 0, 0),
      [theme.breakpoints.up('md')]: {
        flex: 1,
        maxHeight: '100%',
        minHeight: '50px',
        maxWidth: '2px',
        minWidth: '2px',
        margin: theme.spacing(1, 1, 1, 1),
      },
    },
    menuTitle: {
      minHeight: '0',
      margin: theme.spacing(2, 0, 1, 0),
      wordWrap: 'break-word',
      [theme.breakpoints.up('md')]: {
        minHeight: '25px',
        marginBottom: theme.spacing(1),
        wordWrap: 'break-word',
      },
    },
    menuLink: {
      minHeight: '8px',
      marginBottom: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      color: '#FFF',
    },
    copyright: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 1),
    },
    miniDivider: {
      flex: 1,
      height: '100%',
      minWidth: '1px',
      maxWidth: '1px',
      backgroundColor: '#FFF',
      margin: theme.spacing(0, 1.5, 0, 1.5),
    },
    moreLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      margin: theme.spacing(0, 0, 2, 1),
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

const PgeFooter: FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const smallView = useMediaQuery(theme.breakpoints.down('xs'));
  const [moreLinksExpanded, setMoreLinksExpanded] = useState<boolean>(false);

  const transformMenuData = (results: any) => {
    return results.allContentfulFooter.nodes[0];
  };

  const menuData = transformMenuData(
    useStaticQuery(graphql`
      query getFooterContentQuery {
        allContentfulFooter(filter: { node_locale: { eq: "en" } }) {
          nodes {
            policyLinks {
              title
              url
            }
            sections {
              id
              links {
                title
                url
              }
            }
          }
        }
      }
    `),
  );

  const toParagraphs = (...nodes: any) => {
    let key = 0;
    const children = nodes.reduce(
      (result: any, node: any) =>
        result.concat(
          node.split('\n').map((paragraph: string) => (
            <span key={`title-${++key}`}>
              {paragraph}
              <br />
            </span>
          )),
        ),
      [],
    );

    return <span>{children}</span>;
  };

  const buildLinkElement = (link: { title?: string; url?: string }) => {
    if (!link?.title) {
      return null;
    }
    const title = link.title.trim();

    if (!link?.url) {
      return <>{title}</>;
    }
    const url = link.url.trim();

    const isExternal = url.indexOf('http') === 0;
    const isTelephone = url.indexOf('tel') === 0;

    if (isExternal || isTelephone) {
      return (
        <a
          className={classes.link}
          href={url}
          aria-label={title}
          rel={isExternal ? 'noreferrer noopener' : undefined}
          target={isExternal ? '_blank' : '_self'}
        >
          {title}
        </a>
      );
    }

    return (
      <Link className={classes.link} to={url}>
        {title}
      </Link>
    );
  };

  const generateBusiness = (links: { title: string; url: string }[]) => {
    const children: Array<JSX.Element | null> = [];

    links.forEach((link: { title: string; url: string }, index: number) => {
      children.push(
        link.title ? (
          <Typography variant={'subtitle1'} key={index}>
            {buildLinkElement(link)}
          </Typography>
        ) : null,
      );
      if (index + 1 < links.length) {
        children.push(
          <div
            key={`business-divider-${index}`}
            className={classes.miniDivider}
          />,
        );
      }
    });

    return (
      <Box className={classes.corporateContainer}>
        {children.filter(Boolean)}
      </Box>
    );
  };

  const renderMenus = (
    section: { title: string; links: { title: string; url: string }[] },
    index: number,
  ) => {
    const elements: Array<JSX.Element | null> = [];

    const titleElement = toParagraphs(
      !!section?.title ? section?.title.replace('\\n', '\n') : '',
    );
    elements.push(
      titleElement ? (
        <div key={`menuContainer-${index}`} className={classes.menuContainer}>
          {!smallView || !!section?.title ? (
            <Typography variant={'h5'} className={classes.menuTitle}>
              {titleElement}
            </Typography>
          ) : null}
          {section?.links.map((link, linkIndex) => {
            return (
              <div
                key={`subtitle-${index}-${linkIndex}`}
                className={classes.menuLink}
              >
                {!link.title ? null : (
                  <Typography variant={'subtitle1'}>
                    {buildLinkElement(link)}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      ) : null,
    );

    return elements.filter(Boolean);
  };

  const renderSections = (
    sections: {
      id: string;
      title: string;
      links: { title: string; url: string }[];
    }[],
  ) => {
    let elements: Array<JSX.Element> = [];
    if (isMobile) {
      const { moreLinks } = sections.reduce(
        (acc: any, section) => {
          acc.moreLinks.push(section);

          return acc;
        },
        {
          moreLinks: [],
        },
      );

      elements = [
        <div key="footer-section-more-links">
          <Box display="flex">
            <Typography
              className={classes.moreLinks}
              onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              variant={'subtitle1'}
            >
              More Links
            </Typography>
            {moreLinksExpanded ? (
              <ExpandLessIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            )}
          </Box>
          <Collapse in={moreLinksExpanded}>
            {moreLinks.map((section: any, index: number) => (
              <div
                key={`footer-section-${index + 2}`}
                className={classes.section}
              >
                {renderMenus(section, index)}
              </div>
            ))}
          </Collapse>
        </div>,
      ];
    } else {
      elements = sections.reduce(
        (
          acc: any,
          section: { title: string; links: { title: string; url: string }[] },
          index: number,
        ) => {
          acc.push(
            <div key={`footer-section-${index}`} className={classes.section}>
              {renderMenus(section, index)}
            </div>,
          );
          if (index + 1 < sections.length) {
            acc.push(
              <div key={`divider-${index}`} className={classes.divider} />,
            );
          }
          return acc;
        },
        [],
      );
    }

    return (
      <Box
        key={`footer-rendered-sections`}
        display={'flex'}
        flexDirection={'column'}
        className={classes.footerSection}
      >
        {
          <div
            className={`${classes.backgrounds} ${classes.sectionsContainer}`}
          >
            {elements}
          </div>
        }
        <Box
          className={`${classes.backgrounds} ${classes.policyLinks}`}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={2.5}
          mb={1}
        >
          <div className={classes.copyright}>
            <Typography variant={'subtitle1'}>
              &copy; Copyright {new Date().getFullYear()}. All Rights Reserved.
            </Typography>
          </div>
          {generateBusiness(menuData.policyLinks)}
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root} data-testid={TEST_IDS.PGE_FOOTER}>
      <>
        <div className={classes.treeContainer}>
          <Trees style={{ width: '100%', minHeight: 200, height: 200 }} />
        </div>
        <div className={`${classes.backgrounds} ${classes.mobileWrapper}`}>
          {renderSections(menuData.sections)}
        </div>
      </>
    </div>
  );
};

export default PgeFooter;
