import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SearchProvider } from '@elastic/react-search-ui';

import searchConfig from '../config/searchConfig';
import theme from '../themes/theme';
import ErrorBoundary from '../components/error-boundary/ErrorBoundary';
import PathProvider from '../providers/PathProvider';
import { Navigation } from '../components/navigation/Navigation';
import PgeFooter from '../components/navigation/pge-footer';

interface Props {
  readonly element: any;
  readonly props: Readonly<{
    pageContext: Record<string, any>;
    pageResources: Record<string, any>;
  }>;
}

const wrapPageElement: FC<Props> = ({
  element,
  props: { pageContext, pageResources },
}) => {
  const isBrowser = !(typeof window === 'undefined');
  const renderPageComponents = () => (
    <Box
      id={'full-height-shim'}
      display={'flex'}
      flexDirection={'column'}
      minHeight="100vh"
    >
      <Navigation />
      <Box flexGrow={1}>{element}</Box>
      <Box flexShrink={0}>
        <PgeFooter />
      </Box>
      <Helmet>
        <meta name="google" content="notranslate" />
      </Helmet>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <PathProvider context={pageContext.slugPathMap}>
        <CssBaseline />
        <ErrorBoundary>
          {isBrowser && (
            <SearchProvider config={searchConfig}>
              {renderPageComponents()}
            </SearchProvider>
          )}
          {!isBrowser && renderPageComponents()}
        </ErrorBoundary>
      </PathProvider>
    </ThemeProvider>
  );
};

export default wrapPageElement;
