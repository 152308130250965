import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';

import PGELogo from '../logos/PGELogo';

import MobileHeader from '../mobile-header';
import { useIsMobile } from '../../util/style-utils';
import colors from '../../themes/main-colors';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import SearchIcon from '@material-ui/icons/Search';

import './navigation.css';
import { NavigationMegaMenu } from './NavigationMegaMenu';

import TEST_IDS from '../../constants/test_ids';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      padding: theme.spacing(0, 2.5),
      display: 'inline-flex',
      verticalAlign: 'bottom',
    },
    elasticSearchBox: {
      display: 'flex',
      flex: '2 !important',
      justifyContent: 'flex-end',
      '& > form': {
        flex: 1,
        width: '100%',
      },
      '& .sui-search-box__wrapper': {
        width: 300,
      },
      '& .sui-search-box': {
        padding: theme.spacing(0, 1.25, 0, 0),
        justifyContent: 'flex-end',
      },
      '& .sui-search-box__submit': {
        display: 'none',
      },
      '& .sui-search-box__text-input': {
        padding: theme.spacing(1),
      },
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(1.5, 0, 0, -5),
        zIndex: 1,
        fill: colors.noirBlur,
        height: theme.spacing(2),
      },
      '& .MuiButton-outlinedPrimary': {
        marginLeft: theme.spacing(2),
      },
      '& .sui-search-box__autocomplete-container li': {
        textAlign: 'left',
      },
      '& .sui-search-box__section-title': {
        textAlign: 'left',
      },
      '& .sui-search-box__autocomplete-container': {
        top: '99%',
      },
    },
  }),
);

export const Navigation = () => {
  const classes = useStyles();
  const isBrowser = !(typeof window === 'undefined');

  if (useIsMobile()) {
    // TODO: For MVP, we're not showing a title.
    return <MobileHeader title="" />;
  }

  return (
    <>
      <div className="app-bar" data-testid={TEST_IDS.APP_BAR}>
        <Container>
          <div className="logo-wrapper">
            <IconButton
              edge="start"
              aria-label="NAVIGATE_HOME"
              onClick={() => navigate('/')}
            >
              <PGELogo className="logo" color="#fff" viewBox="0 0 752 252" />
            </IconButton>
          </div>

          <div className={`user-wrapper ${classes.elasticSearchBox}`}>
            {isBrowser && (
              <WithSearch
                mapContextToProps={({
                  searchTerm,
                  setSearchTerm,
                  results,
                }: any) => ({
                  searchTerm,
                  setSearchTerm,
                  results,
                })}
              >
                {({ searchTerm, setSearchTerm, results }: any) => {
                  return (
                    <>
                      <SearchBox
                        autocompleteMinimumCharacters={3}
                        autocompleteResults={{
                          sectionTitle: 'Results',
                          titleField: 'title',
                          urlField: 'url',
                          shouldTrackClickThrough: true,
                          clickThroughTags: ['test'],
                        }}
                        autocompleteSuggestions={true}
                        debounceLength={0}
                        onSubmit={async (value: any) => {
                          await navigate('/search?q=' + value);
                          setSearchTerm(value);
                        }}
                      />
                      <SearchIcon
                        style={{ cursor: 'pointer' }}
                        onClick={async (e: any) => {
                          await navigate('/search?q=' + searchTerm);
                          setSearchTerm(searchTerm);
                        }}
                      />
                    </>
                  );
                }}
              </WithSearch>
            )}
          </div>
        </Container>
      </div>
      <NavigationMegaMenu />
    </>
  );
};
