import React, { FC, createContext, useContext } from 'react';

interface Props {
  readonly context?: string;
}

type Check = (slug?: string) => boolean;
type Resolve = (slug?: string) => string | undefined;
type Paths = {
  paths: Record<string, string>;
  check: Check;
  resolve: Resolve;
};

const PathContext = createContext<Paths>({
  paths: {},
  check: () => true,
  resolve: () => '',
});

const PathProvider: FC<Props> = ({ context, children }) => {
  if (!context) {
    return <>{children}</>;
  }

  const paths = JSON.parse(context);
  const check: Check = slug => (!slug ? false : paths.hasOwnProperty(slug));
  const resolve: Resolve = slug => (!slug ? '#' : paths[slug] || slug);

  return (
    <PathContext.Provider value={{ paths, check, resolve }}>
      {children}
    </PathContext.Provider>
  );
};

const usePathContext = () => useContext(PathContext);

export default PathProvider;
export { usePathContext };
