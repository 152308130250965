import React, { FunctionComponent, CSSProperties } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PGELogoProps from './PGELogo.types';

const logoPathStyle: CSSProperties = {
  fill: '#1473c1',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
};

const PGELogo: FunctionComponent<PGELogoProps> = ({
  className,
  color,
  viewBox,
}) => {
  const style = { ...logoPathStyle, fill: color || '#1473c1' };
  return (
    <SvgIcon viewBox={viewBox} className={className}>
      <g id="g10">
        <g id="g12">
          <path
            style={style}
            d="M83.7,101.84h-.1l-17.29,0a.85.85,0,0,0-.83.83l.26,22.34-.13,22.35a.85.85,0,0,0,.25.58.83.83,0,0,0,.58.25h0l9,0a.85.85,0,0,0,.83-.83l-.1-15,7.41,0c11.14,0,17.78-5.83,17.75-15.52,0-4.89-1.59-8.67-4.7-11.22S89.29,101.84,83.7,101.84ZM91.12,117a7.5,7.5,0,0,1-2,5.69,6.78,6.78,0,0,1-4.8,1.73l-8.2,0,0-14.73,7.87,0h0C88.73,109.71,91.11,112.17,91.12,117Z"
          />
          <path
            style={style}
            d="M148.6,122.83l-19.35.06a.87.87,0,0,0-.83.84l0,6.34a.88.88,0,0,0,.83.83l9,0c-.34,6.5-4.19,10.23-10.61,10.25h0c-7.65,0-12-5.75-12.08-15.79,0-10.23,4.27-16.1,11.78-16.12h0c5.38,0,8.85,2.51,10.31,7.42a.94.94,0,0,0,1,.78h0l9.15,0a.7.7,0,0,0,.55-.27.77.77,0,0,0,.14-.64c-2.51-10-10-15.46-21.14-15.46h-.1c-13.49,0-22.54,9.77-22.5,24.22,0,7,2.23,13.07,6.4,17.44s9.87,6.65,16.54,6.65h.08c6.67,0,11.9-1.9,15.53-5.61,4.24-4.3,6.32-11.05,6.19-20.05A.87.87,0,0,0,148.6,122.83Z"
          />
          <path
            style={style}
            d="M187,140.94a.89.89,0,0,0-.84-.83l-21.64.07-.16-12.45,19.35,0a.87.87,0,0,0,.83-.84l0-6.34a.87.87,0,0,0-.83-.83h0l-19.35.06.1-9.9,21.12-.06a.86.86,0,0,0,.83-.83l0-6.34a.85.85,0,0,0-.83-.83h0l-29.94.09a.87.87,0,0,0-.59.25.79.79,0,0,0-.24.58L155,125l-.13,22.35a.88.88,0,0,0,.83.83h0l30.46-.09a.8.8,0,0,0,.58-.26.75.75,0,0,0,.25-.58Z"
          />
          <path
            style={style}
            d="M137.27,24.27c-14.06-14-24.73-.12-24.73-.12l104,78.27S151.33,38.32,137.27,24.27Z"
          />
          <path
            style={style}
            d="M24.28,112.94c-14.06,14-.12,24.7-.12,24.7L102.52,33.77S38.34,98.9,24.28,112.94Z"
          />
          <path
            style={style}
            d="M114.34,227.73c14.06,14,24.73.12,24.73.12l-104-78.27S100.28,213.68,114.34,227.73Z"
          />
          <path
            style={style}
            d="M227.84,113.27,149.48,217.14S213.66,152,227.72,138,227.84,113.27,227.84,113.27Z"
          />
          <path
            style={style}
            d="M299.42,148.24a.71.71,0,0,1-.66-.66l.33-22.53-.33-22.53a.71.71,0,0,1,.66-.66h27.1a.71.71,0,0,1,.66.66v3.65a.71.71,0,0,1-.66.66H305.19l-.34,16.23h20.61a.71.71,0,0,1,.66.66v3.65a.71.71,0,0,1-.66.66H304.85l.34,19.55a.72.72,0,0,1-.67.66Z"
          />
          <path
            style={style}
            d="M346.72,149c-10.33,0-16.16-7-16.16-17.75s5.83-18,16.16-18,16.17,7.09,16.17,17.82S357.13,149,346.72,149Zm0-4.44c6.7,0,9.94-4.77,9.94-13.31s-3.31-13.52-9.94-13.52-10,5-10,13.52S340,144.59,346.72,144.59Z"
          />
          <path
            style={style}
            d="M379.25,149c-7,0-11.39-4.63-11.39-13.58v-6.76l-.14-13.91a.72.72,0,0,1,.67-.66h4.77a.71.71,0,0,1,.66.66l-.13,13.91v6.56c0,6.16,3,8.95,7.22,8.95,4.5,0,8.08-3.58,9.41-10.34v-5.17l-.07-13.91a.71.71,0,0,1,.66-.66h4.84a.71.71,0,0,1,.66.66l-.2,14.91.14,17.89a.72.72,0,0,1-.67.66h-4.57a.71.71,0,0,1-.66-.66l.2-5.17A13.16,13.16,0,0,1,379.25,149Z"
          />
          <path
            style={style}
            d="M403.43,148.24a.71.71,0,0,1-.66-.66l.27-14.91-.2-17.89a.71.71,0,0,1,.66-.66h4.57a.71.71,0,0,1,.66.66l-.13,5.1a13.07,13.07,0,0,1,11.33-6.56c7,0,11.4,4.64,11.4,13.52v6.82l.2,13.92a.72.72,0,0,1-.67.66H426a.71.71,0,0,1-.67-.66l.14-13.92V127.1c0-6.16-2.92-8.94-7.23-8.94s-8.08,3.58-9.4,10.33v5.17l.13,13.92a.71.71,0,0,1-.66.66Z"
          />
          <path
            style={style}
            d="M461.8,148.24a.71.71,0,0,1-.66-.66l.13-4.77a12,12,0,0,1-10.87,6.09c-9.2,0-14.11-7.68-14.11-17.62s5.24-17.83,14.25-17.83a11.86,11.86,0,0,1,10.73,5.77l-.13-16.7a.71.71,0,0,1,.66-.66h4.84a.71.71,0,0,1,.66.66l-.2,20,.2,25.05a.71.71,0,0,1-.66.66Zm-9.87-3.71c5.83,0,9.47-4.38,9.54-13.05s-3.38-13.65-9.41-13.72c-6.56-.07-9.61,5.37-9.61,13C442.45,139,445.5,144.53,451.93,144.53Z"
          />
          <path
            style={style}
            d="M497.91,148.24a.72.72,0,0,1-.67-.66l.14-4.84a12.17,12.17,0,0,1-10.94,6.16c-9.2,0-14.11-7.75-14.11-17.62s5.24-17.83,14.25-17.83a11.85,11.85,0,0,1,10.73,5.83l-.13-4.5a.71.71,0,0,1,.66-.66h4.84a.71.71,0,0,1,.66.66l-.27,16.1.27,16.7a.71.71,0,0,1-.66.66ZM488,144.46c5.9,0,9.47-4.37,9.54-13,.07-8.15-3.05-13.06-8.48-13.52a11.64,11.64,0,0,0-1.59-.07c-6.1.34-8.95,5.5-8.95,12.92C478.49,139,481.54,144.46,488,144.46Z"
          />
          <path
            style={style}
            d="M526.66,147.18a.92.92,0,0,1-.66.86,16.89,16.89,0,0,1-4.58.79c-5,0-9.14-2.58-9.14-10v-20h-3.71a.71.71,0,0,1-.66-.66v-3.38a.71.71,0,0,1,.66-.66h3.71l-.07-7.89a.72.72,0,0,1,.67-.66h4.83a.72.72,0,0,1,.67.66l-.14,7.89H526a.71.71,0,0,1,.66.66v3.38a.71.71,0,0,1-.66.66h-7.76V138.7c0,4.11,1.39,5.36,4.31,5.36a17.75,17.75,0,0,0,3.45-.39.51.51,0,0,1,.66.46Z"
          />
          <path
            style={style}
            d="M532.09,148.24a.71.71,0,0,1-.66-.66l.2-16.9-.2-15.9a.71.71,0,0,1,.66-.66H537a.71.71,0,0,1,.66.66l-.19,15.83.19,17a.71.71,0,0,1-.66.66Zm.07-38.16a.72.72,0,0,1-.67-.67v-5.3a.71.71,0,0,1,.67-.66H537a.71.71,0,0,1,.66.66v5.3a.72.72,0,0,1-.66.67Z"
          />
          <path
            style={style}
            d="M559.25,149c-10.33,0-16.16-7-16.16-17.75s5.83-18,16.16-18,16.17,7.09,16.17,17.82S569.65,149,559.25,149Zm0-4.44c6.69,0,9.94-4.77,9.94-13.31s-3.31-13.52-9.94-13.52-10,5-10,13.52S552.56,144.59,559.25,144.59Z"
          />
          <path
            style={style}
            d="M581.18,148.24a.71.71,0,0,1-.66-.66l.26-14.91-.2-17.89a.72.72,0,0,1,.67-.66h4.57a.71.71,0,0,1,.66.66l-.13,5.1a13,13,0,0,1,11.33-6.56c7,0,11.39,4.64,11.39,13.52v6.82l.2,13.92a.71.71,0,0,1-.66.66h-4.84a.71.71,0,0,1-.66-.66l.13-13.92V127.1c0-6.16-2.91-8.94-7.22-8.94s-8.08,3.58-9.41,10.33v5.17l.14,13.92a.72.72,0,0,1-.67.66Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PGELogo;
