import React, { FunctionComponent } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(2),
      borderBottom: '1px solid #ffffff33',
      fontSize: '16px',
    },
    listLevelLink: {
      display: 'block',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#0388d8',
      padding: theme.spacing(2, 12, 2, 2),
      fontWeight: 400,
      fontSize: '14px',
      borderBottom: '1px solid #36a0e0',
    },
  }),
);

interface SubLinkGroupProps {
  section: any;
  navigateToRoute: (route: string) => (e: any) => Promise<void>;
}

const SubLinkGroup: FunctionComponent<SubLinkGroupProps> = ({
  section,
  navigateToRoute,
}) => {
  const classes = useStyles();

  if (section.url) {
    return (
      <a
        href="#"
        onClick={navigateToRoute(section.url)}
        className={classes.listLevelLink}
      >
        {section.label}
      </a>
    );
  }

  return null;
};

export default SubLinkGroup;
