/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/fonts.css';

const shouldUpdateScroll = (context) => {
  if (
    context &&
    context.routerProps &&
    context.routerProps.location &&
    context.routerProps.location.hash
  ) {
    // preserve scroll position for anchor links
    return true;
  }
  window.scrollTo(0, 0);
  return false;
};

export { default as wrapRootElement } from './src/layouts/Root';
export { default as wrapPageElement } from './src/layouts/Page';
export { shouldUpdateScroll };
