import { ClickAwayListener } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';

import TEST_IDS from '../../constants/test_ids';
import useNavigation from '../../hooks/useNavigation';

export const NavigationMegaMenu = () => {
  const [navigation] = useNavigation();

  const [displayedMenu, setDisplayedMenu] = useState<any>(undefined);
  const [displayedIndex, setDisplayedIndex] = useState<number>(-1);

  const menus = useMemo(() => {
    return (navigation?.menus || []).reduce((acc: any, menuItem: any) => {
      acc.push(menuItem);

      return acc;
    }, []);
  }, [navigation]);

  const handleClose = () => {
    setDisplayedIndex(-1);
    setDisplayedMenu(undefined);
  };

  const handleOpen = (index: number) => {
    setDisplayedIndex(index);
    setDisplayedMenu(menus ? menus[index] : undefined);
  };

  const skipOverMenu = () => {
    if (displayedMenu) {
      handleClose();
    }
  };

  const renderMenuHeader = (
    menuItem: any,
    index: number,
  ): JSX.Element | null => {
    // we are going to check to see if we are in a page or sub page of the
    // path so we can tag the first level of menu as active.
    let active = false;
    if (typeof window !== 'undefined') {
      const fullPath = window.location.pathname;
      const firstPart = fullPath.split('/')[1];
      const linkUrl = menuItem.url?.replace(/\//g, '');
      active = firstPart === linkUrl;
    }
    const displayed = displayedIndex === index ? 'displayed' : '';
    const activeClass = active ? 'active' : '';
    const classes = `${displayed} ${activeClass}`;
    return menuItem.label ? (
      <li key={menuItem.label || index}>
        {menuItem.url ? (
          <Link
            // this looses the tab. keyboarders have to hit enter.
            // onFocus={() => handleOpen(index)}
            to={menuItem.url || '/'}
            onMouseEnter={() => handleOpen(index)}
            onMouseLeave={skipOverMenu}
            onClick={() => {
              handleClose();
              skipOverMenu();
            }}
            aria-label={menuItem.label}
            className={classes}
          >
            {menuItem.label}
          </Link>
        ) : (
          <a
            href="#"
            className={displayedIndex === index ? 'displayed' : ''}
            onMouseEnter={() => handleOpen(index)}
            onMouseLeave={skipOverMenu}
            onClick={(e: any) => {
              e.preventDefault();
            }}
          >
            {menuItem.label}
          </a>
        )}
      </li>
    ) : null;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="nav-anchor" data-testid={TEST_IDS.NAV_ANCHOR}>
        <nav role="navigation" aria-label="main">
          <ul>{(menus || []).map(renderMenuHeader)}</ul>
        </nav>
      </div>
    </ClickAwayListener>
  );
};
