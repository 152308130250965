import palette from '../palette';
import spacing from '../spacing';
import colors from '../main-colors';
const MuiCssBaseline: any = {
  '@global': {
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    a: {
      color: palette.primary.main,
    },

    // Below this line has been ported over from previous work but should not exist
    // these styles should be move to their individual components or component's overrides
    '.onClickLink': {
      color: palette.primary.main,
      cursor: 'pointer',
    },
    '.closeButton': {
      position: 'absolute',
      right: spacing(0),
      top: spacing(-0.5),
      color: colors.cloudGrey,
    },

    // All form input "labels"
    '.MuiFormControl-root label': {
      color: 'rgba(0,0,0,0.7)',
    },
  },
};
export default MuiCssBaseline;
