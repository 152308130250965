import React, { FC } from 'react';
import Helmet from 'react-helmet';
import Noscript from '../components/noscript';

interface Props {
  readonly element: any;
}

const wrapRootElement: FC<Props> = ({ element }) => (
  <>
    <Helmet>
      <link
        rel="preload"
        as="font"
        href="/static/ProximaNova-Regular-f0c836a0323c19f5d3db7d2fa578a4a8.ttf"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/static/ProximaNova-Bold-b0e24b464fe72e698d6639e372651644.ttf"
        type="font/ttf"
        crossOrigin="anonymous"
      />
    </Helmet>
    {element}
    <Noscript />
  </>
);

export default wrapRootElement;
