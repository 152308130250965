import { useStaticQuery, graphql } from 'gatsby';

const AllNavigation = graphql`
  {
    allContentfulNavigation(
      filter: {
        contentful_id: { eq: "6Sdd6KHlAPTNY1IekSeBmV" }
        node_locale: { eq: "en" }
      }
    ) {
      nodes {
        menus {
          contentful_id
          url
          label
        }
      }
    }
  }
`;
export default function useNavigation() {
  try {
    const { allContentfulNavigation } = useStaticQuery(AllNavigation);
    return allContentfulNavigation?.nodes || [];
  } catch {}
}
