// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-basic-template-tsx": () => import("./../../../src/templates/basic-template.tsx" /* webpackChunkName: "component---src-templates-basic-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/news-template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */)
}

