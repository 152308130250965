import React from 'react';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import colors from '../../themes/main-colors';
import MobileNavigationControls from './MobileNavigationControls';
import SubLinkGroup from './SubLinkGroup';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      background: colors.navBarBackground,
      color: colors.white,
    },
    linksList: {
      background: '#0671b4',
      width: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 3,
      position: 'relative',
    },
  }),
);

type MobileNavigationMenuProps = {
  menuOpen: boolean;
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
  navigation: any;
};

const MobileNavigationMenu = ({
  menuOpen,
  toggleMenu,
  navigateToRoute,
  navigation,
}: MobileNavigationMenuProps) => {
  const classes = useStyles();

  return (
    <Drawer
      open={menuOpen}
      anchor="left"
      classes={{ paper: classes.paper }}
      ModalProps={{ onClose: toggleMenu }}
    >
      <MobileNavigationControls
        toggleMenu={toggleMenu}
        navigateToRoute={navigateToRoute}
      />
      <Container className={classes.linksList}>
        <List component="ul" disablePadding>
          {(navigation?.menus || []).map((menu: any) => (
            <SubLinkGroup
              key={menu?.label}
              section={menu}
              navigateToRoute={navigateToRoute}
            />
          ))}
        </List>
      </Container>
    </Drawer>
  );
};

export default MobileNavigationMenu;
